<template>
	<div id="log_manage">
		<jy-query ref="form" :model="form">
			<jy-query-item label="线路:" prop="routeId">
				<!-- <div @click="xlselectInstitutions">
                    <el-input placeholder="请选择" v-model="form.routeName"></el-input>
                </div> -->
				<el-select v-model="form.routeId" placeholder="请选择线路">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="统计日期:" prop="time" :span="1.5">
				<el-date-picker
					v-model="form.time"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:unlink-panels="true"
				></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-row> -->
				<!-- <el-button type="primary">导出</el-button> -->
				<!-- </el-row> -->
			</template>
		</jy-query>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="车辆时段汇总" name="first">
				<periodTime ref="periodTime"></periodTime>
			</el-tab-pane>
			<el-tab-pane label="车辆每日汇总" name="second">
				<dailySummary ref="dailySummary"></dailySummary>
			</el-tab-pane>
		</el-tabs>
		<xlinstitutions-tree
			ref="xlinstitutions"
			@addInstitutions="xladdInstitutions"
			url="/waybillOperate/getTree"
			title="选择线路"
			:defaultProps="props"
		></xlinstitutions-tree>
	</div>
</template>
<script>
import xlinstitutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import periodTime from "@/components/pages/admin/statisticalAnalysis/report/mileage/periodTime";
import dailySummary from "@/components/pages/admin/statisticalAnalysis/report/mileage/dailySummary";
export default {
	data() {
		return {
			// tab
			activeName: "first",
			props: {
				children: "children",
				label: "text",
				value: "id"
			},

			form: {
				routeName: "",
				routeId: "",
				vehicleNo: "",
				time: [],
				beginDate: "",
				endDate: ""
			},
			dataList: [],
			routeOptions: []
		};
	},
	created() {
		this.$nextTick(() => {
			this.$message({
				type: "warning",
				message: "请输入完整统计日期区间范围"
			});
		});
	},
	activated() {
		this.getRouteList();
	},
	components: {
		xlinstitutionsTree,
		periodTime,
		dailySummary
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		getList(click) {
			let option = {
				...this.form
			};
			if (option.time) {
				option.beginDate = option.time[0];
				option.endDate = option.time[1];
			} else {
				option.beginDate = "";
				option.endDate = "";
			}
			if (this.activeName == "first") {
				if (click) {
					if (this.$refs.periodTime.isFirst) {
						this.$refs.periodTime.init(option);
					}
				} else {
					this.$refs.periodTime.init(option);
				}
			} else {
				if (click) {
					if (this.$refs.dailySummary.isFirst) {
						this.$refs.dailySummary.init(option);
					}
				} else {
					this.$refs.dailySummary.init(option);
				}
			}
		},
		// 查询表格
		oncheck() {
			if (!this.form.time || this.form.time.length != 2) {
				this.$message({
					type: "warning",
					message: "请输入完整统计日期区间范围"
				});
				return false;
			}
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.form.routeId = "";
			this.$refs.periodTime.isFirst = true;
			this.$refs.dailySummary.isFirst = true;
			this.oncheck();
		},
		// Tab选择
		handleClick() {
			this.getList("click");
		},
		// 选择线路树
		xlselectInstitutions() {
			this.$refs.xlinstitutions.init();
		},
		// 上级线路机构查询
		xladdInstitutions(data) {
			// console.log(data)
			this.form.routeId = data.id;
			this.form.routeName = data.text;
		}
	}
};
</script>
